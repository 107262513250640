// const { GoogleAuth } = require("google-auth-library");

// const auth = new GoogleAuth();
// import { functions } from "../firebase/config";
interface EmailQuery {
  to: string;
  subject: string;
  queryID: string;
  textContent: string;
}
export const sendEmail = async (
  query: EmailQuery
): Promise<void | Response> => {
  return fetch(
    "https://europe-west1-eresolvevaademo.cloudfunctions.net/sendMail",
    {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      // console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Error:", error);
    });
};

export type RegeneratePromptType =
  | "basic"
  | "formal"
  | "informal"
  | "more"
  | "less";
import { getFunctions, httpsCallable } from "firebase/functions";

export const regenerateResponse = async ({
  type = "basic",
  queryID,
}: {
  type?: RegeneratePromptType;
  queryID: string;
}) => {
  // const token = await auth.getAccessToken();
  // console.log(token);
  // console.log(functions);
  // const addMessage = httpsCallable(functions, "regenEmail");
  // addMessage({ type, queryID })
  //   .then((result: any) => {
  //   // Read result of the Cloud Function.
  //   /** @type {any} */
  //     const data = result.data;
  //     const sanitizedMessage = data.text;
  //   });
  // return "";
  return fetch(
    "https://europe-west1-eresolvevaademo.cloudfunctions.net/regenEmail",
    {
      // mode: "no-cors",
      method: "POST",
      body: JSON.stringify({ type, queryID }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Error:", error);
    });
};
